import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactInfo from "../components/contact-info"

export default function KontaktPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Kontakt" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Kontakt</h2>
            </header>

            <p>
              TRV Projektentwicklung UG<br/>
              Attilastrasse 18, 12529 Schönefeld
            </p>

            <ContactInfo />
          </article>
        </div>
      </div>
    </Layout>
  )
}
